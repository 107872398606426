import React, { useState } from 'react';
import '../styles/GeneralClientForm.css';

interface GenerateClientFormProps {
  onSubmit: (data: { name: string }) => void;
}

const GenerateClientForm: React.FC<GenerateClientFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) {
      alert('Please enter a client name');
      return;
    }
    onSubmit({ name });
    setName('');
  };

  return (
    <form onSubmit={handleSubmit} className="generate-client-form">
      <div className="form-group">
        <label htmlFor="name">Client Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          placeholder="Enter client name"
        />
      </div>
      <div className="form-actions">
        <button type="submit">Generate Client URL</button>
      </div>
    </form>
  );
};

export default GenerateClientForm;