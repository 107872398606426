import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/BulkForm.css';
import { eventTypes } from '../utils/urlUtils';
import { makeAuthenticatedRequest } from '../apiUtils';
import { toast } from 'react-toastify';

interface BulkFormProps {
  onSubmit: (data: any) => void;
  onCancel: () => void; // Added onCancel prop
}

const BulkForm: React.FC<BulkFormProps> = ({ onSubmit, onCancel }) => { // Destructure onCancel
  const [invitationName, setInvitationName] = useState('');
  const [numPasses, setNumPasses] = useState('');
  const [eventType, setEventType] = useState(eventTypes[0]);
  const [guestNames, setGuestNames] = useState('');
  const [tags, setTags] = useState('');
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Prepare data for backend
    const bulkData = {
      invitationName,
      numPasses: parseInt(numPasses) || 0,
      eventType,
      guestNames: guestNames.split(',').map(name => name.trim()),
      tags: tags.split(',').map(tag => tag.trim()),
      expiresAt: expirationDate ? expirationDate.toISOString() : null,
    };

    try {
      const response = await makeAuthenticatedRequest('/api/v1/bulk', {
        method: 'POST',
        body: JSON.stringify(bulkData),
      });

      if (!response.ok) {
        throw new Error('Failed to create bulk URLs');
      }

      const createdUrls = await response.json();
      console.log('Created URLs:', createdUrls);
      onSubmit(createdUrls);
      toast.success('Bulk URLs generated successfully!');
    } catch (error) {
      console.error('Error creating bulk URLs:', error);
      toast.error('Failed to generate bulk URLs. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bulk-form">
      <div className="bulk-form-row">
        <div className="bulk-form-group">
          <input
            type="text"
            placeholder="Nombre de la invitación"
            value={invitationName}
            onChange={(e) => setInvitationName(e.target.value)}
          />
        </div>
        <div className="bulk-form-group">
          <input
            type="text"
            placeholder="Nombres de los invitados"
            value={guestNames}
            onChange={(e) => setGuestNames(e.target.value)}
          />
        </div>
      </div>
      <div className="bulk-form-row">
        <div className="bulk-form-group">
          <input
            type="number"
            placeholder="# de Pases"
            value={numPasses}
            onChange={(e) => setNumPasses(e.target.value)}
          />
        </div>
        <div className="bulk-form-group">
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
          >
            {eventTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="bulk-form-row">
        <div className="bulk-form-group">
          <input
            type="text"
            placeholder="Tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
          <small>Separate tags with commas</small>
        </div>
        <div className="bulk-form-group">
          <DatePicker
            selected={expirationDate}
            onChange={(date: Date | null) => setExpirationDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Fecha de expiración"
          />
        </div>
      </div>
      <button type="submit" className="bulk-submit-button">GENERAR TODO</button>
      <button type="button" onClick={onCancel} className="bulk-cancel-button">Cancelar</button> {/* Added Cancel button */}
    </form>
  );
};

export default BulkForm;