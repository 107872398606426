import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeAppCheckRequest } from '../apiUtils';
import { FaTrash } from 'react-icons/fa';
import './CustomerFormPage.css';
import Modal from 'react-modal';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root'); // Adjust this if your app's root element has a different id

interface Invitado {
  id: string;
  name: string;
  passes: number;
}

const CustomerFormPage: React.FC = () => {
  const { uniqueUrlId } = useParams<{ uniqueUrlId: string }>();
  const [accessCodeInput, setAccessCodeInput] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [invitados, setInvitados] = useState<Invitado[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteGuestId, setDeleteGuestId] = useState<string | null>(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setIsLoading(true);
        const response = await makeAppCheckRequest(`/api/v1/customers/${uniqueUrlId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCustomer(data);
        
        // Load existing guests if any
        if (data.guests && data.guests.length > 0) {
          setInvitados(data.guests.map((guest: { id: string; name: string; passes?: number }) => ({
            id: guest.id,
            name: guest.name,
            passes: guest.passes || 1
          })));
        } else {
          // Add an empty row if no guests exist
          setInvitados([{ id: Date.now().toString(), name: '', passes: 1 }]);
        }
      } catch (error) {
        console.error('Error fetching customer data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomer();
  }, [uniqueUrlId]);

  const handleAccessCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (accessCodeInput === customer?.accessCode) {
      setAccessGranted(true);
    } else {
      alert('Access code is incorrect.');
    }
  };

  const handleInputChange = (id: string, field: string, value: string | number) => {
    setInvitados((prev) =>
      prev.map((inv) => (inv.id === id ? { ...inv, [field]: value } : inv))
    );
  };

  const addNewRow = () => {
    const newRow = { id: Date.now().toString(), name: '', passes: 1 };
    setInvitados((prev) => [...prev, newRow]);
  };

  const deleteRow = (id: string) => {
    setDeleteGuestId(id);
    setModalIsOpen(true);
  };

  const shouldShowDeleteIcon = (invitado: Invitado) => {
    return invitado.name.trim() !== '' || invitado.passes !== 1;
  };

  const handleDeleteRow = (guestId: string) => {
    setDeleteGuestId(guestId);
    setModalIsOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteGuestId && customer?.id) {
      try {
        const response = await makeAppCheckRequest(`/api/v1/customers/${customer.id}/guests/${deleteGuestId}`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setInvitados((prev) => prev.filter((inv) => inv.id !== deleteGuestId));
        console.log('Guest deleted successfully');
      } catch (error) {
        console.error('Error deleting guest:', error);
        // Handle error appropriately
      }
    }
    setModalIsOpen(false);
    setDeleteGuestId(null);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setDeleteGuestId(null);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Perform async operation
    } finally {
      setIsLoading(false);
    }
    openModal();
  };

  const confirmSubmit = async () => {
    // Implement your submit logic here
    console.log('Form submitted');
    closeModal();
  };

  const isSubmitDisabled = invitados.length === 1 && invitados[0].name.trim() === '';

  const hasNewEmptyRow = invitados.some(inv => inv.name === '' && inv.passes === 1);

  if (isLoading) {
    return <div className="customer-form-page">Cargando...</div>;
  }

  if (!accessGranted) {
    return (
      <div className="customer-form-page">
        <form className="access-code-form" onSubmit={handleAccessCodeSubmit}>
          <h2>Ingrese el código de acceso</h2>
          <input
            type="text"
            value={accessCodeInput}
            onChange={(e) => setAccessCodeInput(e.target.value)}
            placeholder="Ingrese el código de acceso"
          />
          <button type="submit">Enviar</button>
        </form>
      </div>
    );
  }

  return (
    <div className={`customer-form-page ${isLoading ? 'loading' : ''}`}>
      <div className="customer-form-content">
        <h2>Bienvenido, {customer.name}</h2>
        <p className="important-message">
          Por favor, asegúrese de escribir los nombres exactamente como desea que aparezcan en su invitación. Esto incluye todos los acentos, mayúsculas y minúsculas, signos de puntuación, etc.
        </p>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Pases</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitados.map((inv, index) => (
                <tr key={inv.id}>
                  <td>
                    <input
                      type="text"
                      value={inv.name}
                      onChange={(e) => handleInputChange(inv.id, 'name', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      value={inv.passes}
                      onChange={(e) =>
                        handleInputChange(inv.id, 'passes', parseInt(e.target.value))
                      }
                    />
                  </td>
                  <td className="delete-cell">
                    <button 
                      className="delete-button" 
                      onClick={() => deleteRow(inv.id)}
                      aria-label={`Delete ${inv.name || `guest ${index + 1}`}`}
                      title={`Delete ${inv.name || `guest ${index + 1}`}`}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="button-row">
          <button 
            onClick={addNewRow} 
            className="add-button"
          >
            Agregar nueva fila
          </button>
          <button 
            onClick={handleSubmit} 
            disabled={isSubmitDisabled}
            className={isSubmitDisabled ? 'submit-button disabled' : 'submit-button'}
          >
            Enviar formulario
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete"
        className="confirm-modal"
        overlayClassName="confirm-modal-overlay"
      >
        <h2>Confirmación</h2>
        <p>¿Está seguro de que desea eliminar este invitado?</p>
        <div className="modal-buttons">
          <button onClick={confirmDelete}>Sí, eliminar</button>
          <button onClick={closeModal}>Cancelar</button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerFormPage;