// frontend/firebase.config.ts

import { initializeApp, getApps } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  ReCaptchaEnterpriseProvider,
  AppCheck, // Import the AppCheck type
} from 'firebase/app-check';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // Remove adminKey from here
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

// Initialize App Check
let appCheck: AppCheck; // Explicitly type 'appCheck'

if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  // Initialize App Check without debugToken
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY!
    ),
    isTokenAutoRefreshEnabled: true,
    // Remove debugToken: true
  });

  console.log('Connecting to Firebase emulators...');
  connectAuthEmulator(getAuth(app), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
} else {
  // Use the reCAPTCHA provider in production
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY!
    ),
    isTokenAutoRefreshEnabled: true,
  });
}

export const auth = getAuth(app);
export const db = getFirestore(app);
export { appCheck };
