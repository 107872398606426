import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useFirebase } from '../contexts/FirebaseContext';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file
import { auth } from '../firebase.config';

const Login: React.FC = () => {
  const { auth } = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isInitializing, setIsInitializing] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth!, (user) => {
      if (user) {
        navigate('/admin/dashboard');
      } else {
        setIsInitializing(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (auth) {
        await signInWithEmailAndPassword(auth, email, password);
        // Firebase will handle the auth state change and navigate to dashboard
      } else {
        throw new Error('Authentication is not initialized');
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  if (isInitializing) {
    return <div className="loading-container">Loading...</div>;
  }

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <h2 className="login-title">Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;