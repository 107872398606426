import React, { useState, useEffect, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  FilterFn,
} from '@tanstack/react-table';
import { FaSort, FaSortUp, FaSortDown, FaLink } from 'react-icons/fa';
import '../styles/CustomerManagement.css';
import GenerateClientForm from './GenerateClientForm';
import { toast } from 'react-toastify';
import { getAllCustomers } from '../apiUtils'; // Import the API function

interface Customer {
  id: string;
  name: string;
  uniqueUrlId: string;
  accessCode: string;
  createdAt: string;
}

const CustomerManagement: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const fetchedCustomers = await getAllCustomers();
      setCustomers(fetchedCustomers);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch customers');
      setLoading(false);
      toast.error('Failed to fetch customers');
    }
  };

  const columns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Unique URL ID',
        accessorKey: 'uniqueUrlId',
      },
      {
        header: 'Access Code',
        accessorKey: 'accessCode',
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
          <button
            onClick={() => handleAccessUrl(row.original.uniqueUrlId)}
            className="action-button"
          >
            <FaLink /> Access URL
          </button>
        ),
      },
    ],
    []
  );

  const globalFilterFn: FilterFn<any> = (row, columnId, filterValue, addMeta) => {
    const value = row.getValue(columnId);
    return value != null &&
      value.toString().toLowerCase().includes(filterValue.toLowerCase());
  };

  const table = useReactTable({
    data: customers,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: globalFilterFn,
  });

  const handleAddOrUpdateCustomer = async (data: any) => {
    try {
      // Implement the logic to add or update a customer
      // For example, you might interact with Firebase here
      // await addCustomerToFirestore(data);
      toast.success('Cliente agregado/actualizado exitosamente!');
      fetchCustomers(); // Refresh the customer list
    } catch (error) {
      toast.error('Error al guardar el cliente. Inténtalo de nuevo.');
    }
  };

  const handleAccessUrl = (uniqueUrlId: string) => {
    // Implement the logic to access the client's URL
    // For example, you might open a new tab with the client's URL
    const clientUrl = `${window.location.origin}/customer/${uniqueUrlId}`;
    window.open(clientUrl, '_blank');
  };

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="customer-management">
      <h1>Customer Management</h1>
      
      <GenerateClientForm onSubmit={handleAddOrUpdateCustomer} />

      <div className="search-filter">
        <input
          type="text"
          placeholder="Search all columns..."
          className="search-input"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>
      <div className="table-wrapper">
        <table className="customer-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerManagement;
