import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useFirebase } from '../contexts/FirebaseContext';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { auth } = useFirebase();
  const location = useLocation();

  if (!auth) {
    // Firebase not initialized yet
    return <div>Loading...</div>;
  }

  const user = auth.currentUser;

  if (!user) {
    // Not authenticated, redirect to login
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;