import React, { useState, useEffect } from 'react';
import '../styles/URLManagement.css';
import { FaEdit, FaTrash, FaBan } from 'react-icons/fa';
import { useFirebase } from '../contexts/FirebaseContext';
import Modal from './Modal';
import { ModalProvider } from 'styled-react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from './ConfirmationModal';
import GenerateURLForm from './GenerateURLForm';
import BulkGenerationForm from './BulkForm';
import { makeAuthenticatedRequest } from '../apiUtils';

interface Url {
  id: string;
  longUrl: string;
  shortCode: string;
  shortUrl: string;
  createdAt: string;
  expiresAt: string | null;
  clicks: number;
  tags: string[];
  disabled: boolean;
  isNew?: boolean;
  isDeleting?: boolean;
}

const URLManagement: React.FC = () => {
  const [urls, setUrls] = useState<Url[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editingUrl, setEditingUrl] = useState<Url | null>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [urlToDelete, setUrlToDelete] = useState<Url | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [activeForm, setActiveForm] = useState<string | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://invtr.co';
  
  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      const response = await makeAuthenticatedRequest(`${API_BASE_URL}/api/v1`, {
        method: 'GET',
      });
      const data = await response.json();
      setUrls(data.urls);
    } catch (err) {
      setError('Failed to fetch URLs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrUpdateUrl = async (data: Omit<Url, 'id'>) => {
    try {
      if (editingUrl) {
        const response = await makeAuthenticatedRequest(`${API_BASE_URL}/api/v1/${editingUrl.shortCode}`, {
          method: 'PUT',
          body: JSON.stringify(data),
        });
        const updatedUrl = await response.json();
        setUrls(prevUrls => prevUrls.map(url => url.id === updatedUrl.id ? updatedUrl : url));
        toast.success('URL updated successfully!');
      } else {
        // Use the data directly without making another API call
        const newUrl = data as Url; // Assuming the data includes all necessary fields
        setUrls([{ ...newUrl, isNew: true }, ...urls]);
        toast.success('URL added successfully!');
        setTimeout(() => {
          setUrls((prevUrls) =>
            prevUrls.map((url) =>
              url.id === newUrl.id ? { ...url, isNew: false } : url
            )
          );
        }, 2000);
      }
      closeModal();
    } catch (error) {
      toast.error('Failed to save URL. Please try again.');
    }
  };

  const handleDeleteUrl = async (url: Url) => {
    try {
      setUrls((prevUrls) =>
        prevUrls.map((u) =>
          u.id === url.id ? { ...u, isDeleting: true } : u
        )
      );

      await makeAuthenticatedRequest(`${API_BASE_URL}/api/v1/${url.shortCode}`, {
        method: 'DELETE',
      });
      
      toast.success(`URL "${url.shortCode}" deleted successfully!`);

      setTimeout(() => {
        setUrls((prevUrls) => prevUrls.filter((u) => u.id !== url.id));
      }, 1000);
    } catch (error) {
      toast.error('Failed to delete URL. Please try again.');
      setUrls((prevUrls) =>
        prevUrls.map((u) =>
          u.id === url.id ? { ...u, isDeleting: false } : u
        )
      );
    }
  };

  const handleBulkDelete = () => {
    if (selectedRows.length > 0) {
      setIsDeleteModalOpen(true);
    }
  };

  const confirmBulkDelete = async () => {
    try {
      setUrls((prevUrls) =>
        prevUrls.map((u) =>
          selectedRows.includes(u.id) ? { ...u, isDeleting: true } : u
        )
      );

      await makeAuthenticatedRequest(`${API_BASE_URL}/api/v1/bulk`, {
        method: 'DELETE',
        body: JSON.stringify({ shortCodes: selectedRows }),
      });

      toast.success(`${selectedRows.length} URL(s) deleted successfully!`);
      setSelectedRows([]);
      
      setTimeout(() => {
        fetchUrls();
      }, 1000);
    } catch (error) {
      toast.error('Failed to delete URLs. Please try again.');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const openModal = (url?: Url) => {
    setEditingUrl(url || null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingUrl(null);
    setActiveForm(null);
  };

  const toggleForm = (formName: string) => {
    if (activeForm === formName) {
      setActiveForm(null);
      setIsModalOpen(false);
    } else {
      setActiveForm(formName);
      setIsModalOpen(true);
    }
  };

  const filteredUrls = urls.filter((url) => {
    const matchesGlobal = Object.values(url).some((value) =>
      typeof value === 'string' &&
      value.toLowerCase().includes(globalFilter.toLowerCase())
    );
    const matchesTag = url.tags.some((tag) =>
      tag.toLowerCase().includes(tagFilter.toLowerCase())
    );
    return matchesGlobal && matchesTag;
  });

  const truncate = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <ModalProvider>
      <div className="url-management">
        <h1>URL Dashboard</h1>
        <div className="actions-row">
          <button onClick={() => toggleForm('generate-passes')}>GENERAR PASES</button>
          <button onClick={() => toggleForm('generate-url')}>GENERAR URL</button>
          <button onClick={() => toggleForm('bulk-generation')}>GENERACIÓN MASIVA</button>
        </div>

        <div className="search-filter">
          <input
            type="text"
            placeholder="Search all columns..."
            className="search-input"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <input
            type="text"
            placeholder="Filter tags..."
            className="filter-input"
            value={tagFilter}
            onChange={(e) => setTagFilter(e.target.value)}
          />
          {selectedRows.length > 0 && (
            <button className="bulk-delete-button" onClick={handleBulkDelete}>
              <FaTrash /> Delete Selected ({selectedRows.length})
            </button>
          )}
        </div>

        <table className="url-table">
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>Short Code</th>
              <th>Long URL</th>
              <th>Tags</th>
              <th>Clicks</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUrls.map(url => (
              <tr
                key={url.id}
                className={
                  url.isNew
                    ? 'highlight-add'
                    : url.isDeleting
                    ? 'highlight-delete'
                    : ''
                }
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(url.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows([...selectedRows, url.id]);
                      } else {
                        setSelectedRows(selectedRows.filter((id) => id !== url.id));
                      }
                    }}
                  />
                </td>
                <td>{truncate(url.shortCode, 35)}</td>
                <td>{truncate(url.longUrl, 65)}</td>
                <td>{url.tags.join(', ')}</td>
                <td>{url.clicks}</td>
                <td>
                  <button className="action-button" onClick={() => openModal(url)}><FaEdit /></button>
                  <button className="action-button" onClick={() => handleDeleteUrl(url)}><FaTrash /></button>
                  <button className="action-button" onClick={() => {
                    // Implement disable/enable functionality
                  }}><FaBan /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {activeForm === 'generate-url' && (
            <GenerateURLForm onSubmit={handleAddOrUpdateUrl} onCancel={closeModal} />
          )}
          {activeForm === 'bulk-generation' && (
            <BulkGenerationForm onSubmit={handleAddOrUpdateUrl} onCancel={closeModal} />
          )}
        </Modal>

        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmBulkDelete}
          message="Are you sure you want to delete the selected URLs?"
        />

        <ToastContainer />
      </div>
    </ModalProvider>
  );
};

export default URLManagement;
