export const normalizeAndEncodeName = (name: string) => {
  name = name.replace(/["'`~!@#$%^&*()_+=\[\]{};:\\|\/?]/g, '');
  name = name
    .replace(/\s*,\s*/g, ', ')
    .replace(/\s+/g, ' ')
    .trim();
  name = name.replace(/([a-záéíóúñ])\s+([A-ZÁÉÍÓÚÑ])/g, '$1, $2');
  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  let capitalizedNames = name.split(' ').map(capitalize).join(' ');
  capitalizedNames = capitalizedNames.replace(/\bY\s+(?=[iIhH][^\s]*)/g, 'e ');
  capitalizedNames = capitalizedNames.replace(
    /e\s+(\w)/g,
    (match, p1) => `e ${p1.toUpperCase()}`
  );
  const normalized = capitalizedNames
    .replace('&', 'y')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

  return { capitalizedNames, normalized };
};

export const generateLongUrl = (
  invitationName: string,
  guestNames: string,
  guestCount: number,
  eventType: string
) => {
  const { capitalizedNames } = normalizeAndEncodeName(guestNames);
  const encodedNames = encodeURIComponent(
    capitalizedNames.replace(/\by\s+(?=[iIhH][^\s]*)/g, 'e ')
  );

  let baseUrl;
  switch (eventType) {
    case 'Boda-01':
      baseUrl = 'https://invitarco.wixstudio.io/nos-casamos/';
      break;
    case 'Boda-02':
      baseUrl = 'https://invitarco.wixstudio.io/boda2/';
      break;
    case 'Mis Quince 02':
      baseUrl = 'https://invitarco.wixstudio.io/mis-quince2/';
      break;
    case 'Mis Quince 03':
      baseUrl = 'https://invitarco.wixstudio.io/misquince3/';
      break;
    case 'Quinceañera Temática':
      baseUrl = 'https://invitarco.wixstudio.io/mis-quince-tematica/';
      break;
    default:
      baseUrl = 'https://invitarco.wixstudio.io/nos-casamos/';
  }

  let url = `${baseUrl}${invitationName}`;
  const params = new URLSearchParams();

  if (guestCount > 0) {
    params.append('pases', guestCount.toString());
  }

  if (guestNames) {
    params.append('invitados', encodedNames);
  }

  const queryString = params.toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  return url;
};

export const generateShortCode = (
  invitationName: string,
  guestNames: string,
  guestCount: number
) => {
  const { normalized: normalizedInvitationName } =
    normalizeAndEncodeName(invitationName);
  let shortCode = normalizedInvitationName;

  if (guestNames) {
    const names = guestNames.split(',')[0].trim(); // Take names before the first comma
    const { normalized: normalizedGuestNames } = normalizeAndEncodeName(names);
    const guestNameParts = normalizedGuestNames.split('-');
    const firstThreeNames = guestNameParts.slice(0, 3).join('-'); // Take up to first three names
    shortCode += `-${firstThreeNames}`;
  } else if (guestCount > 0) {
    shortCode += `-pases-${guestCount}`;
  }

  return shortCode;
};

export const eventTypes = [
  'Boda-01',
  'Boda-02',
  'Mis Quince 02',
  'Mis Quince 03',
  'Quinceañera Temática',
];
