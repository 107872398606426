import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/GenerateURLForm.css';
import { eventTypes, generateLongUrl, generateShortCode, normalizeAndEncodeName } from '../utils/urlUtils';
import { makeAuthenticatedRequest } from '../apiUtils';
import { toast } from 'react-toastify';
import { Url } from '../types/urlTypes';

interface GenerateURLFormProps {
  onSubmit: (data: Omit<Url, "id">) => Promise<void>;
  initialData?: Url;
  onCancel?: () => void;
}

const GenerateURLForm: React.FC<GenerateURLFormProps> = ({ onSubmit }) => {
  const [invitationName, setInvitationName] = useState('');
  const [numPasses, setNumPasses] = useState('');
  const [eventType, setEventType] = useState(eventTypes[0]);
  const [guestNames, setGuestNames] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  useEffect(() => {
    if (invitationName) {
      setTags([invitationName]);
    }
  }, [invitationName]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const guestCount = parseInt(numPasses) || 0;
    const shortCode = generateShortCode(invitationName, guestNames, guestCount);
    const longUrl = generateLongUrl(invitationName, guestNames, guestCount, eventType);

    const { capitalizedNames: normalizedGuestNames } = normalizeAndEncodeName(guestNames);

    // Prepare data for backend
    const urlData = {
      longUrl,
      shortCode,
      expiresAt: expirationDate ? expirationDate.toISOString() : null,
      tags,
      invitationName,
      guestNames: normalizedGuestNames,
      numPasses: guestCount,
      eventType
    };

    try {
      const response = await makeAuthenticatedRequest('/api/v1', {
        method: 'POST',
        body: JSON.stringify(urlData),
      });

      if (!response.ok) {
        throw new Error('Failed to create short URL');
      }

      const createdUrl = await response.json();
      console.log('Created URL:', createdUrl);
      onSubmit(createdUrl);
    } catch (error) {
      console.error('Error creating short URL:', error);
      toast.error('Failed to generate URL. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="generate-url-form">
      <div className="form-row">
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre de la invitación"
            value={invitationName}
            onChange={(e) => setInvitationName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombres de los invitados"
            value={guestNames}
            onChange={(e) => setGuestNames(e.target.value)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <input
            type="number"
            placeholder="# de Pases"
            value={numPasses}
            onChange={(e) => setNumPasses(e.target.value)}
          />
        </div>
        <div className="form-group">
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
          >
            {eventTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Tags"
            value={tags.join(', ')}
            readOnly
          />
        </div>
        <div className="form-group">
          <DatePicker
            selected={expirationDate}
            onChange={(date: Date | null) => setExpirationDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="Fecha de expiración"
          />
        </div>
      </div>
      <button type="submit" className="submit-button">GENERAR TODO</button>
    </form>
  );
};

export default GenerateURLForm;
