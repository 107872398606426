// src/apiUtils.ts
import { getToken } from 'firebase/app-check';
import { appCheck, auth } from './firebase.config';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://invtr.co';

export async function makeAuthenticatedRequest(
  url: string,
  options: RequestInit
) {
  const token = await auth.currentUser?.getIdToken();
  const apiKey = process.env.REACT_APP_ADMIN_API_KEY;
  const appCheckToken = await getToken(appCheck, /* forceRefresh */ false);

  const headers = new Headers(options.headers);
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `Bearer ${token}`);
  headers.set('X-API-Key', apiKey || '');
  headers.set('X-Firebase-AppCheck', appCheckToken.token);

  const response = await fetch(url, {
    ...options,
    headers,
  });

  if (!response.ok) {
    const errorBody = await response.text();
    console.error('Response error:', {
      status: response.status,
      statusText: response.statusText,
      body: errorBody,
    });
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response;
}

export const makeAppCheckRequest = async (
  url: string,
  options: RequestInit = {}
) => {
  // Get App Check token
  const appCheckTokenResponse = await getToken(
    appCheck,
    /* forceRefresh= */ false
  );
  if (!appCheckTokenResponse || !appCheckTokenResponse.token) {
    throw new Error('Failed to retrieve App Check token');
  }

  // Include App Check token in headers
  const headers = {
    ...options.headers,
    'X-Firebase-AppCheck': appCheckTokenResponse.token,
  };

  // Make the request with updated headers
  const response = await fetch(url, {
    ...options,
    headers,
  });

  return response;
};

export const addGuestToCustomer = async (
  customerId: string,
  guestName: string
) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/customers/${customerId}/guests`,
    {
      guestName,
    }
  );
  return response.data;
};

export const getGuestsByCustomer = async (customerId: string) => {
  const response = await axios.get(
    `${API_BASE_URL}/api/customers/${customerId}/guests`
  );
  return response.data;
};

export const getAllCustomers = async () => {
  const response = await makeAuthenticatedRequest(
    `${API_BASE_URL}/api/v1/customers`,
    { method: 'GET' }
  );
  return response.json();
};
