import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { initializeAppCheck, AppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { auth, db, appCheck } from '../firebase.config';

// Define the shape of the Firebase context
interface FirebaseContextProps {
  app: FirebaseApp | null;
  auth: Auth | null;
  db: Firestore | null;
  appCheck: AppCheck | null;
}

// Create the context
const FirebaseContext = createContext<FirebaseContextProps>({
  app: null,
  auth: null,
  db: null,
  appCheck: null,
});

// Create a provider component
export const FirebaseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);

  useEffect(() => {
    const app = getApps()[0];
    setFirebaseApp(app);
  }, []);

  return (
    <FirebaseContext.Provider value={{ app: firebaseApp, auth, db, appCheck }}>
      {children}
    </FirebaseContext.Provider>
  );
};

// Custom hook to use Firebase context
export const useFirebase = () => useContext(FirebaseContext);