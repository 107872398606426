import React, { useState } from 'react';
import { useFirebase } from '../contexts/FirebaseContext';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { FaBars, FaLink, FaUsers } from 'react-icons/fa'; // Added FaUsers
import URLManagement from '../components/URLManagement';
import CustomerManagement from '../components/CustomerManagement'; // Import the new component

const Dashboard: React.FC = () => {
  const { auth } = useFirebase();
  const navigate = useNavigate();
  const [user, setUser] = useState<string | null>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeComponent, setActiveComponent] = useState<string>('url-management');

  React.useEffect(() => {
    if (!auth) {
      navigate('/admin/login');
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        navigate('/admin/login');
      } else {
        setUser(currentUser.email);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const handleLogout = async () => {
    try {
      if (auth) {
        await signOut(auth);
        navigate('/admin/login');
      } else {
        throw new Error('Firebase Auth is not initialized.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          <FaBars />
        </button>
        <div
          className={`menu-item ${activeComponent === 'url-management' ? 'active' : ''}`}
          onClick={() => setActiveComponent('url-management')}
        >
          <FaLink />
          <span className="menu-item-text">URL Management</span>
        </div>
        <div
          className={`menu-item ${activeComponent === 'customer-management' ? 'active' : ''}`}
          onClick={() => setActiveComponent('customer-management')}
        >
          <FaUsers />
          <span className="menu-item-text">Customer Management</span>
        </div>
        {/* Add more menu items here as needed */}
      </div>
      <div className="main-content">
        <header className="dashboard-header">
          <h1 className="dashboard-title">URL Dashboard</h1>
          <div className="logout-section">
            <span className="user-email">Logged in as: {user}</span>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </header>

        {activeComponent === 'url-management' && <URLManagement />}
        {activeComponent === 'customer-management' && <CustomerManagement />}
      </div>
    </div>
  );
};

export default Dashboard;